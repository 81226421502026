/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer-bg-color{
    background-color: #030e22;
}

//actual footer-section

// .footer-section {
//     padding: 100px 0 0px;
//     overflow: hidden;
//     // Responsive
//     @media #{$tablet-device}{
//         padding: 80px 0 0px;
//     }
//     @media #{$large-mobile}{
//         padding: 60px 0 0px;
//     }
// }

//customized for footer-section to stabilize footer bottom padding
.footer-section {
    padding: 40px 0 0px;
    overflow: hidden;
    // Responsive
    @media #{$tablet-device}{
        padding: 40px 0 0px;
    }
    @media #{$large-mobile}{
        padding: 40px 0 0px;
    }
}

.footerLogo{
    color: $white;
    font-weight:900;
    font-size:1.5rem;
}

.copyright {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding: 20px 15px;
    border-top: 1px solid $gray-200;
    // color: $white;
    color: $footer-grey;
    margin-bottom: 0;
}

.footer-widget-content ul li{
    padding-right: 0px !important;
 
}


.spaceAbove{
   
    @media #{$tablet-device}{
        margin-top: 30px;
    }
    @media #{$large-mobile}{
        margin-top: 25px;
    }

}