/*----------------------------------------*/

/*  01. Template default CSS
/*----------------------------------------*/
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }e

    &:-ms-input-placeholder {
        @content
    }
}


#noHoverColor{
    a:hover{
    color: black !important;
    }
}




/* Common Style */

@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body,
.site-wrapper {
    height: 100%;
}

body {
    font-family: $body-font;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.74;

    position: relative;

    visibility: visible;
    overflow-x: hidden;
    letter-spacing: 0;

    color: $secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    font-weight: 700;
    line-height: 1.3;

    margin-top: 0;

    color: $heading-color;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: inherit;

    margin: 0;
}

h1,
.h1 {
    @media #{$large-mobile} {
    }

    @media #{$small-mobile} {
    }
}

h2,
.h2 {
@media #{$large-mobile} {
    }

    @media #{$small-mobile} {
    }
}

h3,
.h3 {
    @media #{$large-mobile} {
    }

    @media #{$small-mobile} {
    }
}

h4,
.h4 {
    @media #{$large-mobile} {
    }

    @media #{$small-mobile} {
    }
}

h5,
.h5 {
    @media #{$large-mobile} {
    }

    @media #{$small-mobile} {
    }
}

h6,
.h6 {
    @media #{$large-mobile} {
    }

    @media #{$small-mobile} {
    }
}

p:last-child {
    margin-bottom: 0;
}

a,
button {
    line-height: inherit;

    display: inline-block;

    cursor: pointer;
    text-decoration: none;

    color: inherit;
}

a,
button,
img,
input {
    transition: $transition;
}

*:focus {
    outline: none !important;
}

a:focus {
    text-decoration: none;

    color: inherit;
    outline: none;
}

a:hover {
    text-decoration: none;

    color: $primary;
}

button,
input[type="submit"] {
    cursor: pointer;
}

img {
    max-width: 100%;
}

input,
textarea {
    @include placeholder {
        opacity: 1;
    }
}

ul {
    &:last-child {
        margin-bottom: 0;
    }

    & li {
    }
}


hr {
    border-top-width: 2px;
}

svg:not(:root) {
	overflow: hidden;
}

/* Common Classes */
.section-border-bottom{
    border-bottom: 1px solid #e7e7e7;
}

.fix {
    overflow: hidden;
}
.before-zindex-9{
    position: relative;
    &::before{
        z-index: 9 !important;
    }
}
.box-shadow{
    box-shadow: 0 0 40px rgba(51,51,51,0.1);
}

.section,
.main-wrapper {
    float: left;

    width: 100%;
}

.bg-color-1{
    background-color: #f8faff;
}

.bg-primary-blue{
    background-color: #f8faff;
}

.Player__videoContainer___2TVqS{
    width: 100% !important;
}






.overlay{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: linear-gradient(to right,  rgba(3,15,39,0.7) 0%,rgba(3,15,39,0) 100%); 
    }
}
.overlay-two{
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #030f27; 
        opacity: 0.7;
        z-index: -1;
    }
}

.overlay-less{
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #030e22; 
//         background: rgb(255,255,255);
// background: linear-gradient(90deg, rgba(255,255,255,1) 5%, rgba(3,14,34,1) 100%);
        opacity: 0.4;
        z-index: -1;
    }
}

.overlays-three{
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #aaa8a8; 
        opacity: 0.5;
        z-index: -1;
    }
}

@for $i from 0 through 20 {
    [data-overlay="#{$i * .1}"] {
        &::before {
            opacity: 0.1 * $i;
        }
    }
}

/* Background Image */
[data-bg-image] {
    background-repeat: repeat;
    background-position: top center;

    &:not(.bg-pattern) {
        
    }
}

.section-bg-image{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
}

/* Scroll Up */

.scroll-top{
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 999;
    box-shadow: 0 30px 50px rgba(0,0,0,.03);
    display: block;
    padding: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    border: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    // background-color: $primary;
    background-color: $Ikabit-Blue;
    background-size: 200% auto;
    background-position: left center;
    color: $white;
    transition: $transition;
    overflow: hidden;

    @media #{$extra-small-mobile}{
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
    }

    &.show{
        visibility: visible;
        opacity: 1;
        bottom: 60px;
    }

    i{
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        transition: $transition;
    }

    .arrow-top{
        transform: translate(-50%, -50%);
    }

    .arrow-bottom{
        transform: translate(-50%, 80px);
    }

    &:hover{
        background-position: right center;
        .arrow-top{
            transform: translate(-50%, -80px);
        }
        .arrow-bottom{
            transform: translate(-50%, -50%);
        }
    }
}



/* swiper default styles */
.swiper-pagination{
    position: static;
    display: block;
    line-height: 1;
    margin-top: 20px;
  .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      border: 0;
      border-radius: 0;
      background: transparent;
      opacity: 1;
      position: relative;
      outline: none;
      margin-right: 20px;
      margin: 0 8px !important;
      &:last-child{
        margin-right: 0;
      }
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        width: 8px;
        height: 8px;
        background: #d8d8d8;
        z-index: 1;
        transition: $transition;
      }
  
      &:hover{
        &:before{
          width: 12px;
          height: 12px;
          opacity: 1;
          background: $heading-color;
        }
      }
  }
      
      
  .swiper-pagination-bullet-active{
      &:before{
        width: 12px;
        height: 12px;
        opacity: 1;
        background: $heading-color;
      }
    }
  }
  
  .swiper-pagination.swiper-pagination-white  {
      & .swiper-pagination-bullet {
          margin: 8px;
          &:hover{
              &:before{
                  width: 12px;
                  height: 12px;
                  opacity: 1;
                  background: #fff;
              }
          }
      }
      & .swiper-pagination-bullet-active::before {
          opacity: 1;
          background: #fff;
          width: 16px;
          height: 16px;
          box-shadow: 0 0 9px #ededed;
      }
  }
  
  .swiper-nav-button {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s, opacity 0.1s linear 2s;
      background-image: none;
      text-align: center;
      user-select: none;
      outline: none;
      width: 48px;
      height: 48px;
      font-size: 24px;
      color: #6d70a6;
      border: 0;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
      opacity: 0;
      visibility: hidden;
      transform: translate(0, -50%);
      margin: 0;
      top: 50%;
      &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          color: #fff;
          background: #fff;
      }
  }
  .swiper-nav-button:hover  {
      color: #fff;
       &::before {
          color: #fff;
          background: $heading-color;
      }
  }
//   .swiper-container {
//       transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//   }
  .swiper-container:hover {
      & .swiper-nav-button {
          opacity: 1;
          visibility: visible;
      }
  }
  
  .swiper-nav-button i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  .swiper-nav-button i::before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      content: '\f104';
  }
  .swiper-nav-prev i::before {
       content: '\f104';
  }
  .swiper-button-next i::before {
      content: '\f105';
  }
//   .swiper-wrapper {
//       transition-timing-function: linear !important;
//   }


#changeOnScroll{
    // style={{height:'9rem',width:'9rem', position:'absolute',top:'10px'}}
    height: 10rem;
    width: 10rem;
    position: absolute;
    top: 1px;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
    

}

.logoResize{
    height: 4.5rem !important;
    width: 4.5rem !important;
    position: static !important;
        


    
}

.logoTextResize{
    visibility: visible !important;
    position: static !important;
    margin-left: 20px;
    
    // display: none;
}





#HomeCard_hover{
    color: $gray-500 !important;


&:hover{
    color: $Ikabit-Blue !important;
}

}   

// .BorderClass{
//     border-right: 4px solid #030f27;
//     height: 12rem;

// }


.checked{
    color: orange;
}


.listF {
    li::before{
    list-style-type: Bullet Triangle Blue Icon 16x16;
    // color: red;
    }
}

@import '~loaders.css/src/animations/pacman.scss';

// @import '~loaders.css/src//ball-pulse-rise.scss';

@import '~loaders.css/src/animations/line-scale.scss';

@import '~loaders.css/src/animations/semi-circle-spin.scss';

.loader-active{
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    z-index: 99999999;
    // animation: fadeOut 1s 7s;
    animation-fill-mode: forwards;
}

// .loader-active{
//     display: flex;
//     position: absolute;
//     justify-content: center;
//     align-items: center;
 
//     width: 50px;
//     height: 50px;
//     z-index: 99999;
//     animation: fadeOut 5s 2s;
//     animation-fill-mode: forwards;
// }


.starBack{
    position: absolute;
    z-index: 0;
}
.blue{
    color: #030e22;

}
.starTop{
    position: absolute;
    z-index: 12;
}

.LoaderBackground{
    height: 100vh;
    width: 100%;
    background-color:#030e22;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999998;
    // animation: fadeOut 2s 7s;
    animation-fill-mode: forwards;
}

// .removeB{
//     display: none;

// }




// Responsiveness
// Responsive Variables
// $extraBig-device: "only screen and (min-width: 1600px)";
@media #{$extraBig-device}{
    // About Us Page
    .about-content-area p {
        max-width: fit-content !important;
        margin-right: 20px;
    }
    .about-image-areas {
        margin-bottom: 8% !important;
    }
    .page-title-section.section.section-padding-top {
        background-size: cover !important;
        

    }
    

    // Contact Us Page
    //Yant Page
    .content-paddingTop {
        padding-top: 55px!important;
    }
    .row.row-cols-lg-3.row-cols-md-3.row-cols-3 {
        margin-left: 100px;
        margin-right: 100px;
    }
    div#ContactIkabittext {
        font-size: 13px !important;
    }
    h6.marginBottom-2r-h6 {
        margin: 5rem 0rem 1rem !important;
    }
    .desc {
        height: 180px !important;
    }
    // a.btn.btn-blue.btn-hover-primary.cs1-button {
    //     margin-top: 12%;
    // }

    //Best8Eats Page
    .moreB8E {
        margin-top: 8% !important;
        padding-left: 24.5% !important;
        position: absolute !important;
        z-index: 100 !important;
    }
    .container.b8s-hero-content {
        margin-left: 8.5%;
        margin-top: 5%;
    }

    .intro-content.mt-xl-8.mt-lg-8.mt-md-8.mt-sm-8.mt-xs-8 {
        margin-top: 1% !important;
        margin-bottom: 3% !important;
    }

    // Home Page
    .col.align-self-center {
        margin-top: 10% !important;
    }
    .intro-content-two .desc {
        max-width: 700px !important;
        margin: 0 auto !important;
    }

    .hover-card.overlay-two.flipcard1-image {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .hover-card.overlay-two.flipcard2-image {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .hover-card.overlay-two.flipcard3-image {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    a#btn_hover {
        margin-top: 0 !important;
    }
    .desc.homepage-hero-desc {
        height: 125px !important;
    }
    .section.section-padding.contact-section.overlay-two {
        background-size: cover !important;
    }
    
    //Intellectual Property Page
    // .col.mb-6.ip-card.aos-animate {
    //     margin-left: auto;
    //     margin-right: auto;
    // }
    // IP Details Page
    .desc.ip-detail-desc {
        height: fit-content !important;
    }
    .work-left.work-details.ycm-wd.aos-init.aos-animate{
        width: 100% !important;
        height: 100% !important;
        }
        
    .portfolio-main-info.ycm-portfolio {
        height: 100% !important;
    }}

// $laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
@media #{$laptop-device}{
    // About Us Page
    .about-content-area p {
        max-width: fit-content !important;
        margin-right: 20px;
    }
    .page-title-section.section.section-padding-top {
        background-size: cover !important;
    }
    .desc.our-products-yant {
        height: auto !important;
    }
    .desc.our-products-b8e {
        height: auto !important;
    }

    // Contact Us Page
    //Yant Page
    section.meet_yant.section.content-paddingTop.mb-5 {
        padding-top: 55px !important;
    }
    .row.row-cols-lg-3.row-cols-md-3.row-cols-3 {
        margin-left: 100px;
        margin-right: 100px;
    }
    h6.marginBottom-2r-h6 {
        margin: 8rem 0rem 1rem !important;
    }
    div#ContactIkabittext {
        font-size: 15px !important;
    }
    .swiper-container.swiper-container-coverflow.swiper-container-3d.swiper-container-initialized.swiper-container-horizontal.mySwiper {
        margin-left: 0px !important;
    }


    // Best8Eats Page
.container.b8s-hero-content {
    margin-left: 8.5%;
    margin-top: 13%;
}

.intro-content.mt-xl-8.mt-lg-8.mt-md-8.mt-sm-8.mt-xs-8 {
    margin-top: 0% !important;
    margin-bottom: 1% !important;
}



    .desc {
        height: 180px !important;
    }
    img.cs1-bg-image {
        margin-top: 140px !important;
    }
    h2.cs1-heading {
        margin-top: 140px !important;
    }
    .overlay.less.cl-bg-image {
        margin-top: 60px!important;
        width: 57% !important;
    }    

    // Home Page
    .col.align-self-center {
        margin-top: 10% !important;
    }
    .intro-content-two .desc {
        max-width: 700px;
        margin: 0 auto;
    }
    p.fact-desc {
        font-size: 1rem;
    }
    a#btn_hover {
        margin-top: 0 !important;
    }
    .desc.homepage-hero-desc {
        height: 125px !important;
    }
    .section.section-padding.contact-section.overlay-two {
        background-size: cover;
    }
    //Intellectual Property Page
    // .col.mb-6.ip-card.aos-animate {
    //     margin-left: auto;
    //     margin-right: auto;
    // }
        // IP Details Page
    .desc.ip-detail-desc {
        height: fit-content !important;
    }
    .work-left.work-details.ycm-wd.aos-init.aos-animate{
        height: 100% !important;
    }
    .portfolio-main-info.ycm-portfolio {
    height: 100% !important;
    }
}

// $desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
@media #{$desktop-device}{
    // About Us Page
    // .col.vertical-divider {
    //     display: none !important;
    // }
    .col-12.mb-3.aos-animate.aos-init {
        margin-left: auto;
        margin-right: auto;
    }

    .desc.our-products-yant {
        height: auto !important;
    }
    .desc.our-products-b8e {
        height: auto !important;
    }


    .about-content-area p {
        max-width: fit-content !important;
        margin-right: 20px;
    }
    .contact-info {
        height: 250px;
    }    
    .page-title-section.section.section-padding-top {
        background-size: cover !important;
    }

    // Contact Us Page
    // Yant Page
    div#YantHeroText {
        left: 10% !important;
    }

    section.meet_yant.section.content-paddingTop.mb-5 {
        padding-top: 50px;
    }
    .col.ml-30.ml-1024 {
        margin-left: 100px !important;
    }
    .section.content-paddingTop.marginBottom {
        margin-bottom: 65px !important;
    }
    h6.marginBottom-2r-h6 {
        margin: rem 0rem 1rem !important;
    }
    div#ContactIkabittext {
        font-size: 15px !important;
    }
    .swiper-container.swiper-container-coverflow.swiper-container-3d.swiper-container-initialized.swiper-container-horizontal.mySwiper {
        margin-left: 0px !important;
    }


    // Best8Eats Page

    .container.b8s-hero-content {
        margin-left: 8.5%;
        margin-top: 17%;
    }

    .intro-content.mt-xl-8.mt-lg-8.mt-md-8.mt-sm-8.mt-xs-8 {
        margin-top: 0% !important;
        margin-bottom: 1% !important;
    }

    h4.intro-content-h4 {
        font-size: 100%;
    }

    p.intro-content-desc-p * {
        font-size: 80%;
    }

    .intro-content .desc {
        max-width: 700px;
        height: auto !important;
    }




    
    .desc {
        height: 180px !important;
    }
    img.cs1-bg-image {
        margin-top: 140px !important;
    }
    h2.cs1-heading {
        margin-top: 140px !important;
    }
    .overlay.less.cl-bg-image {
        margin-top: 60px !important;
        width: 61% !important;
    }
    .moreB8E {
        padding-left: 9.5% !important;
        margin-top: 23% !important;
    }

    // Home Page
    .col.align-self-center {
        margin-top: 15% !important;
    }
    .intro-content-two .desc {
        max-width: 700px;
        margin: 0 auto;
    }
    a#btn_hover {
        margin-top: 0 !important;
    }
    p.fact-desc {
        font-size: 1rem !important;
    }
    .desc.homepage-hero-desc {
        height: 125px !important;
    }
    .section.section-padding.contact-section.overlay-two {
        background-size: cover;
    }
    //Intellectual Property Page
    // .col.mb-6.ip-card.aos-animate {
    //     margin-left: auto;
    //     margin-right: auto;
    // }
    .desc.ip-detail-desc {
        height: fit-content !important;
    }

}



// $tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
@media #{$tablet-device}{
    #changeOnScroll {
        height: 7rem;
        width: 7rem;
        position: absolute;
        top: 1px;
        filter: drop-shadow(5px 5px 5px #222);
    }
    .logoResize {
        height: 3rem !important;
        width: 3rem !important;
        position: static !important;
    }

    // About Us
    .col.vertical-divider {
        display: none !important;
    }
        .desc.our-products-yant {
        height: auto !important;
    }
    .desc.our-products-b8e {
        height: auto !important;
    }
    .col-12.mb-3.our-value.aos-animate {
        margin-left: auto;
        margin-right: auto;
    }
    .about-image-areas {
        padding-right: 35px;
    }
    .about-content-area.founders-story {
        margin-top: 3rem !important;
    }
    .about-content-area p {
        max-width: fit-content !important;
        margin-right: 25px;
    }
        .contact-info {
            height: 335px !important;
        }
        .page-title-section.section.section-padding-top {
            background-size: cover !important;
        }

    // Contact Us Page
    .sticky.text {
        padding: 25px !important;
    }
    div#ContactIkabittext {
        font-size: 15px !important;
    }

    //Yant Page

    .page-title-section.section.section-padding-top.yhImage {
        background-position-x: 32%;
    }
    
    div#YantHeroText {
        vertical-align: middle;
        position: relative;
        left: 0 !important;
    }

    .skill-video.skill-video-yant {
        padding: 0 !important;
    }

    
    .contact-Information.yant-in-action-image {
        margin-left: 0 !important;
    }

    ul.FList.fa-ul {
        text-align: left !important;
    }
    
    .swiper-container.swiper-container-coverflow.swiper-container-3d.swiper-container-initialized.swiper-container-horizontal.mySwiper {
        margin-left: auto !important;
        width: 100% !important;
    }
    
    .section-title-two.mb-8 >p {
        max-width: fit-content !important;
    }
    h6.marginBottom-2r-h6 {
        margin: -1.5rem 0rem 1rem !important;
    }
    .marginBottom-2r {
        text-align: left    ;
        margin-bottom: 3rem!important;
    }

    .about-content-area {
        margin-top: 125px !important;
    }
    .swiper-text {
        margin: 0rem 20px 0px !important;
    }


    // Best8Eats Page
    .container.b8s-hero-content {
        margin-left: 8.5%;
        margin-top: 17%;
    }

    .intro-content.mt-xl-8.mt-lg-8.mt-md-8.mt-sm-8.mt-xs-8 {
        margin-top: 0% !important;
        margin-bottom: 0% !important;
    }

    h4.intro-content-h4 {
        font-size: 100%;
    }

    p.intro-content-desc-p * {
        font-size: 80%;
    }

    .intro-content .desc {
        height: auto !important;
    }

    .row.flipcards-row {
        text-align: -webkit-center;
    }
    .hover-card.overlay-two.flipcard1-image {
        text-align: left;
        margin-bottom: 3rem;
    }
    .hover-card.overlay-two.flipcard2-image {
        text-align: left;
        margin-bottom: 3rem;
    }
    .hover-card.overlay-two.flipcard3-image {
        text-align: left;
    }

    .overlay.less.cl-bg-image {
        width: 460px !important;
        height: 350px !important;
        margin-top: 75px;
    }
    img.cs1-bg-image {
        margin-top: 100px !important;
    }
    h2.cs1-heading {
        margin-top: 100px !important;
    }
    .desc {
        height: 180px !important;
    }
    .mt-md-17 {
        margin-top: 70px !important;
    }
    h4.cs-heading-title {
        font-size: 100% !important;
    }
    p.cs-heading-desc {
        font-size: 90% !important;
    }

    h4.cs1-heading-title {
        font-size: 100% !important;
    }
    p.cs1-heading-desc {
        font-size: 90% !important;
    }
    .moreB8E {
        margin-top: 25% !important;
        padding-left: 3% !important;
        position: absolute !important;
        z-index: 100 !important;
    }

    // Home Page
    .col.align-self-center {
        margin-top: 18% !important;
    }
    .intro-content-two .desc {
        max-width: 700px;
        margin: 0 auto;
    }
    .skill-with-video-content {
        text-align: center;
    }
    .skill-video {
        padding: 5% 20% !important;
    }
    .video-popup-area {
        margin-top: 0 !important;
    }
    .skill-with-video-content {
        margin-bottom: 50px !important;
    }    
    a#btn_hover {
        margin-top: 0 !important;
    }
    .desc.homepage-hero-desc {
        height: 125px !important;
    }
    .col.mb-6.aos-init.aos-animate {
        margin-left: auto;
        margin-right: auto;
    }

    p.card-text {
        padding: 30px 10px 5px !important;
        text-align: center;
        color: rgb(238, 238, 238);
    }
    .section.section-padding.contact-section.overlay-two {
        background-size: cover;
    }
    .col-7.factSlider-img-div {
        display: none !important;
    }
    .col.factSlider-text-div {
        margin-left: 5% !important;
    }
    // IP Details Page
    .desc.ip-detail-desc {
    height: fit-content !important;
    }
    .work-left.work-details.ycm-wd.aos-init.aos-animate, .portfolio-main-info.ycm-portfolio {
        height: 100% !important;
    }
    .col-lg-3.col-md-12.col-12.ycm {
        margin-bottom: 30px;
    }
}

// $large-mobile: "only screen and (max-width: 767px)";
@media #{$large-mobile}{

    #leftB{
        left: 0rem !important
    }

    #logoTextResize{
        display: none;
    }

    #changeOnScroll {
        height: 7rem;
        width: 7rem;
        position: absolute;
        top: 1px;
        filter: drop-shadow(5px 5px 5px #222);
    }
    .logoResize {
        height: 3rem !important;
        width: 3rem !important;
        position: static !important;
    }
    // About Us Page
    .vertical-divider{
        display: none !important;
    }

    .desc.our-products-yant {
        height: auto !important;
    }
    .desc.our-products-b8e {
        height: auto !important;
    }
    .col-12.mb-3.our-value.aos-animate {
        margin-left: auto;
        margin-right: auto;
    }

    .about-image-areas {
        padding-right: 35px;
    }

    .about-content-area.founders-story {
        margin-top: 2rem !important;
    }

    .about-content-area p {
        max-width: fit-content !important;
        margin-right: 35px;
    }
    .page-title-section.section.section-padding-top {
        background-size: cover !important;
    }
    .page-title.extra-pad.about-us-breadcrumb {
        margin-top: 15% !important;
        margin-left: 25% !important;
    }
    // Contact Us Page
    .page-title.extra-pad.contactUs-breadCrumb {
        margin-top: 10%;
    }
    div#ContactIkabittext {
        font-size: 15px !important;
    }
    .sticky.text {
        padding: 25px !important;
    }

    // Yant Page
    div#YantHeroText {
        vertical-align: middle;
        left: 0% !important;
        position: relative;
    }
    .skill-video.skill-video-yant {
        padding: 0 !important;
    }

    .skill-video.skill-video-yant {
        padding: 0 !important;
    }

    .section-title-two.mb-8 >p {
        max-width: fit-content !important;
    }
    .swiper-text {
        margin: 0rem 20px 0px !important;
    }
    .about-content-area {
        margin-top: 125px !important;
    }

    // Best8Eats Page
    .container.b8s-hero-content {
        margin-left: 8.5%;
        margin-top: 25%;
    }

    .intro-content.mt-xl-8.mt-lg-8.mt-md-8.mt-sm-8.mt-xs-8 {
        margin-top: 0% !important;
        margin-bottom: 0% !important;
    }

    h4.intro-content-h4 {
        font-size: 90%;
    }

    p.intro-content-desc-p * {
        font-size: 75%;
    }
    .row.flipcards-row {
        text-align: -webkit-center;
    }
    .hover-card.overlay-two.flipcard1-image {
        text-align: left;
        margin-bottom: 3rem;
    }
    .hover-card.overlay-two.flipcard2-image {
        text-align: left;
        margin-bottom: 3rem;
    }
    .hover-card.overlay-two.flipcard3-image {
        text-align: left;
    }


    .intro-content .desc {
        height: auto !important;
    }

    .container.flipcards1 {
        text-align: -webkit-center;
    }
    .page-title.extra-pad .heading-align {
        margin-top: 15% !important;
        margin-left: 0 !important;
    }

    .overlay.less.cl-bg-image {
        width: 460px !important;
        height: 350px !important;
        margin-top: 75px !important;
    }
    img.cs1-bg-image {
        margin-top: 140px !important;
    }
    h2.cs1-heading {
        margin-top: 140px !important;
    }
    .desc {
        height: 180px !important;
    }
    h4.cs-heading-title {
        font-size: 90% !important;
    }
    p.cs-heading-desc {
        font-size: 75% !important;
    }

    h4.cs1-heading-title {
        font-size: 90% !important;
    }
    p.cs1-heading-desc {
        font-size: 75% !important;
    }
    .overlay.less.cl-bg-image {
        width: 360px !important;
        height: 250px !important;
        margin-top: 75px !important;
    }
    .moreB8E {
        margin-top: 25% !important;
        padding-left: 13% !important;
        position: absolute !important;
        z-index: 100 !important;
    }

    // Home Page
    .col.align-self-center {
        margin-top: 20% !important;
    }
    .intro-content-two .desc {
        max-width: 700px;
        margin: 0 auto;
    }
    a#btn_hover {
        margin-top: 0 !important;
    }
    .desc.homepage-hero-desc {
        height: 125px !important;
    }
    .skill-video {
        padding: 5% 20% !important;
    }
    .video-popup-area {
        margin-top: 0 !important;
    }

    .skill-with-video-content {
        margin-bottom: 40px !important;
        text-align: center !important;
    }
    p.card-text {
        padding: 80px 10px 5px !important;
        text-align: center;
        color: rgb(238, 238, 238);
    }
    .section.section-padding.contact-section.overlay-two {
        background-size: cover;
    }
    .col-7.factSlider-img-div {
      
      //  display: none !important;
    }
    
    .col.factSlider-text-div {
        margin-left: 5% !important;
    }
    // IP Details Page
        .desc.ip-detail-desc {
        height: fit-content !important;
    }
    .col-lg-3.col-md-12.col-12.ycm {
        margin-bottom: 30px;
    }
        
}

// $small-mobile: "only screen and (max-width: 575px)";
@media #{$small-mobile}{

    #leftB{
        left: 0rem !important
    }

    #logoTextResize{
        display: none;
    }
    // About Us Page
    .desc.our-products-yant {
        height: auto !important;
    }
    .desc.our-products-b8e {
        height: auto !important;
    }

    .col-4.mb-3.aos-init.aos-animate {
        margin: auto;
    }
    
    .about-image-areas {
        padding-right: 35px;
    }
    .about-content-area p {
        max-width: fit-content !important;
        margin-right: 30px;
    }
    .page-title-section.section.section-padding-top {
        background-size: cover !important;
    }
    .page-title.extra-pad.about-us-breadcrumb {
        margin-top: 20% !important;
        margin-left: 25% !important;
    }
    img#changeOnScroll {
        position: fixed !important;
        top: 15px !important;
        left: 50px !important;
    }
    // Contact Us Page
    p.sub-title {
        font-size: 75% !important;
    }
    div#ContactIkabittext {
        font-size: 15px !important;
    }
    //Yant Page

    .page-title-section.section.section-padding-top.yhImage {
        background-position-x: 32%;
    }
    h6.yUD-h6 {
        font-size: .75rem;
    }
    p.yUD-p {
        font-size: .5rem;
    }

    div#YantHeroText {
        vertical-align: middle;
        left: 0%;
        position: relative;
    }

    ul.FList.fa-ul {
        text-align: left !important;
    }

    .skill-video.skill-video-yant {
        padding: 0 important;
    }
    .contact-Information.yant-in-action-image {
        margin-left: 0 !important;
    }

    .contact-Information.yant-in-action-image {
        margin: 0 !important;
    }
    .swiper-container.swiper-container-coverflow.swiper-container-3d.swiper-container-initialized.swiper-container-horizontal.mySwiper {
        margin: 0 !important;
    }
    .marginBottom-2r {
        text-align: left;
        margin-bottom: 2rem!important;
    }
    .card.align-items-center {
        margin: auto;
    }

    // Best8Eats Page
    .container.b8s-hero-content {
        margin-left: 8.5%;
        margin-top: 30%;
    }

    .intro-content.mt-xl-8.mt-lg-8.mt-md-8.mt-sm-8.mt-xs-8 {
        margin-top: 0% !important;
        margin-bottom: 0% !important;
    }

    h4.intro-content-h4 {
        font-size: 90%;
    }

    p.intro-content-desc-p * {
        font-size: 80%;
    }

    .intro-content .desc {
        height: auto !important;
    }

    .page-title.extra-pad.heading-align {
        margin-left: 0 !important;
    }

    .col-7.custImage {
        display: none !important;
    }

    .col-5.cust-desc {
        margin: auto !important;
    }
    h2.cs-heading {
        margin-top: 21% !important;
    }
    p.cs-heading-desc {
        height: auto !important;
    }

    .col-7.restImage {
        display: none !important;
    }
    .col-5.mobileWidth {
        margin: auto !important;
    }
    h2.cs1-heading {
        margin-top: 0 !important;
    }
    .desc.descMtext {
        height: auto !important;
        margin-bottom: 2rem;
    }
    

    
    .moreB8E {
        margin-top: 27% !important;
        padding-left: 13% !important;
        position: absolute !important;
        z-index: 100 !important;
    }
    .section.moreB8E-bg-img {
        background-image: none !important;
    }

    // Home Page
    h2.title.homepage-hero-title {
        font-size: 2.5rem !important;
    }
    .col.align-self-center {
        margin-top: 35% !important;
    }
    .intro-content-two .desc {
        max-width: 700px;
        margin: 0 auto;
    }
    a#btn_hover {
        margin-top: 0 !important;
    }
    .desc.homepage-hero-desc {
        height: 125px !important;
    }
    .section.section-padding.contact-section.overlay-two {
        background-size: cover;
    }
    .col-7.factSlider-img-div {
       //display: none !important;
    }
    .col.factSlider-text-div {
        margin-left: 5% !important;
    }
    p.fact-desc {
        margin-right: 7% !important;
    }
}

// $extra-small-mobile: "only screen and (max-width: 479px)";
@media #{$extra-small-mobile}{

    #leftB{
        left: 0rem !important
    }

    #logoTextResize{
        display: none;
    }
    // About Us Page
    .desc.our-products-yant {
        height: auto !important;
        margin: 0% 10% !important;
    }
    .desc.our-products-b8e {
        height: auto !important;
        margin: 0% 10% !important;
    }
    a#btn_hover\ s-a-demo {
        width: auto !important;
    }

    .section-title.text-center.aos-init.aos-animate {
        width: 100% !important;
    }
    
    .page-title-section.section.section-padding-top {
        background-size: cover !important;
     
    
      
    }
    .page-title-section.section.section-padding-top.overlay-two.ipImage{
        background-size: 376px 376px !important;
    }
    .page-title-section.section.section-padding-top.yhImage{
       // background-size: 400px 480px !important;
       background-position: 29%;

    }
    .page-title.extra-pad.about-us-breadcrumb {
        margin-top: 20% !important;
        margin-left: 30% !important;
    }
    img#changeOnScroll {
        position: fixed !important;
        top: 15px !important;
        left: 50px !important;
    }
    .icon.flag-svg {
        margin-bottom: 30px !important;
    }

    // Contact Us Page
    .page-title.extra-pad.contactUs-breadCrumb h1 {
        margin-top: 15%;
        font-size: 100%;
    }
    .page-title.extra-pad.contactUs-breadCrumb p {
        font-size: .8rem;
    }
    .sub-title-text.about-us-p1 p, .sub-title-text.about-us-p2 p {
        font-size: .8rem !important;
    }
    .contact-form-section.section.section-padding-b100.content-paddingTop {
        width: 75%!important;
    }
    div#ContactIkabittext {
        font-size: 15px !important;
    }
    .offset-lg-2.col-lg-8.container-479 {
        padding-left: 0 !important;
        width: 90% !important;
    }
    //Yant Page
    .swiper-container.swiper-container-coverflow.swiper-container-3d.swiper-container-initialized.swiper-container-horizontal.mySwiper {
        margin-left: auto !important;
    }
    .swiper-text {
        max-width: 90% !important;
        margin: 0rem !important;
    }
    .section.section-paddingTopBottom.mt-10.slider-section.aos-animate {
        max-width: 100%;
    }
    [data-aos^=fade][data-aos^=fade].aos-animate {
        opacity: 1;
        transform: translateZ(0);
        width: 100% !important;
    }
    h6.marginBottom-2r-h6 {
        margin: 3rem 0rem 2rem !important;
        text-align: left;
    }



    // Best8Eats Page
    .container.b8s-hero-content {
        margin-left: 8.5%;
        margin-top: 40%;
    }

    .intro-content.mt-xl-8.mt-lg-8.mt-md-8.mt-sm-8.mt-xs-8 {
        margin-top: 0% !important;
        margin-bottom: 0% !important;
    }

    h4.intro-content-h4 {
        font-size: 70%;
    }

    p.intro-content-desc-p * {
    }

    .intro-content .desc {
        height: auto !important;
    font-size: 75%;
    }

    h2.swiper-div-h2-text {
        font-size: 100%;
    }
    h4.swiper-div-h4-text {
        font-size: 100%;
    }
    .page-title.extra-pad.heading-align {
        margin-left: 0 !important;
    }

    // [data-aos^=fade][data-aos^=fade].aos-animate {
    //     opacity: 1;
    //     transform: translateZ(0);
    //     width: 133% !important;
    // }
    h4.cs-heading-title {
        font-size: 80% !important;
    }
    p.cs-heading-desc {
        font-size: 60% !important;
    }

    h4.cs1-heading-title {
        font-size: 80% !important;
    }
    p.cs1-heading-desc {
        font-size: 60% !important;
    }
    .moreB8E {
        margin-top: 10% !important;
        padding-left: 13% !important;
        position: absolute !important;
        z-index: 100 !important;
    }

    // Home Page
    .swiper-container.swiper-container-initialized.swiper-container-horizontal.testimonial-slider.mt-7.aos-init.aos-animate {
        width: 100% !important;
        margin-top: 5px !important;
    }
    .col.align-self-center {
        margin-top: 45% !important;
    }
    .intro-content-two .desc {
        max-width: 700px;
        margin: 0 auto;
    }
    a#btn_hover {
        margin-top: 0 !important;
    }
    .desc.homepage-hero-desc {
        height: 90px !important;
    }
    .skill-with-video-content {
        text-align: center;
    }
    .section.section-padding.contact-section.overlay-two {
        background-size: cover;
    }
    .col-7.factSlider-img-div {
        //display: none !important;
        width: fit-content;
        height: fit-content;
    }
    .row.factSlider-row.Lres{
        flex-wrap: wrap-reverse;
        
    }
    .factSlider-img.Lres{
        height:100% !important;
       padding: 20px;
        
        }
    .col.factSlider-text-div {
        margin-left: 5% !important;
    }
    p.fact-desc {
        font-size: 1rem !important;
    }

    // IP Details Page
    .work-left.work-details.ycm-wd.aos-init.aos-animate {
        width: 100% !important;
    }
    .content.mb-5.ip-details-desc-content.aos-init.aos-animate {
        width: 100% !important;
    }
}

@media only screen and (min-width:410px) and (max-width:412px){
    #HomeBanner.overlay-two{
        width: 100%;
    height: 250px !important;
    }
    .overlay-two.home-banner-video{
        width: 100%;
        height: 250px !important;
    }
    
   
}

// $extra-small-mobile: "only screen and (max-width: 425px)";
@media only screen and (max-width: 425px){


    #leftB{
        left: 0rem !important
    }

    #logoTextResize{
        display: none;
    }

    // Home Page

    #HomeBanner.overlay-two {
        height: 250px !important;
        width: 100% !important;
        margin: 0 !important;
        position: absolute;
        object-fit: cover;
        right: 0;
        top: 0px !important;
        
        z-index: -1;
    }

    h2.title.homepage-hero-title, .title.homepage-hero-title.h2 {
        font-size: 1.3rem !important;
    }
    .col.align-self-center {
        margin-top: 21% !important;
        padding-right: 0% !important;
    }
    .desc.homepage-hero-desc {
        height: 40px !important;
    }
    .desc.homepage-hero-desc {
        height: auto !important;
        margin-bottom: 2% !important;
    }

    .overlay-two.home-banner-video {
        width: 100%;
        height: 250px !important;
        
    }

    #changeOnScroll {
        height: 3rem;
        width: 3rem;
        position: absolute;
        top: 1px;
        filter: drop-shadow(5px 5px 5px #222);
    }
    img#changeOnScroll {
        position: fixed !important;
        top: 10px !important;
        left: 50px !important;
    }

    .logoResize {
        height: 3rem !important;
        width: 3rem !important;
        position: static !important;
        // margin-top: 11px !important;
    }

    p.fact-desc {
    font-size: .9rem !important;
    }

    .card.overlay-two.efeb-img {
        height: 450px!important;
        background-position: center;
        
    }
    .section.home-demo-section.aos-animate {
        width: 100% !important;
    }
    h3.title.home-schedule-section-title {
        font-size: 1.5rem !important;
    }
    .section.home-demo-section.aos-init.aos-animate {
        padding: 0 0px 50px 0px !important;
    }
    .section.section-padding.contact-section.overlay-two.home-schedule-section-bg-img {
        height: 51%;
    }

    //Yant Page
    .page-title-section.section.section-padding-top.yant-hero-image {
        height: 250px;
    }
    .page-title.extra-pad.yant-hero-text {
        position: relative;
        left: 0% !important;
    }
    .row.yant-in-action-list {
        text-align: left;
    }
    .offset-xl-1.col-xl-6.col-lg-6.col-12.meet-yant-cards {
        width: 100%;
    }
    .scroll-div.yant-cust-endo-desc {
        width: 100% !important;
    }

    .align-items-center.join-yant.aos-init.aos-animate {
        width: 100% !important;
    }
    h6.marginBottom-2r-h6 {
        margin: 3rem 0rem 2rem !important;
        text-align: left;
    }

    .section.section-paddingTopBottom.mt-10.slider-section.aos-init.aos-animate {
       //margin-top: 150px !important;
    }

    // Best8Eats Page
    .container.b8s-hero-content {
        margin-left: 8.5%;
        margin-top: 0%;
    }

    .col-5.mobileWidth {
     width: 75% !important;
  // width: 100%;
}
.intro-section {
    height: auto !important;
}
.section.moreB8E-bg-img{
    height: 215px !important;
    
}
.moreB8E{
    margin-top: 0%;
    
}



    // About Us Page
    .page-title-section.section.section-padding-top.about-us-hero-img {
        height: 45% !important;
    }
    .page-title.extra-pad.about-us-breadcrumb {
        margin-top: 5% !important;
    }
    .page-title-section.section.section-padding-top.about-us-hero-img {
        height: 350px !important;
    }
    .col-5.cust-desc {
        margin: auto !important;
        width: 75%;
    }
    h2#noJustify {
        text-align: center;
        font-size: 23px;
        padding-bottom: 25px;
    }
    p#centerText {
        text-align: center;
    }

    // Contact Us Page
    .page-title.extra-pad.contactUs-breadCrumb h1, .page-title.extra-pad.contactUs-breadCrumb .h1 {
        margin-top: 0%;
        font-size: 100%;
    }
    .page-title.extra-pad.contactUs-breadCrumb {
        margin-top: 0%;
    }

    .sticky.text.sticky-section-container.aos-init.aos-animate {
        width: 100% !important;
        height: 100% !important;
    }
    
    section.Sticky_main.section-paddingBottom {
        // width: 100% !important;
        display: block !important;
    }
    
    .scrollmagic-pin-spacer {
        width: 100% !important;
    }

    .static.static-content {
        width: 100% !important;
    }
    div#ContactIkabittext {
        font-size: 14px !important;
    }
    .offset-lg-2.col-lg-8.container-479.container-425 {
        width: 100% !important;
        padding: 0% !important
    }
    .sticky.text.sticky-section-container.aos-init.aos-animate {
        width: 100% !important;
        height: 65% !important;
    }
    .contact-form.mb-9.aos-init.aos-animate {
        width: 100% !important;
    }
    // IP Details Page
    
    #leftB{
        left: 0rem !important
    }

}




// $extra-small-mobile: "only screen and (max-width: 360px)";
@media only screen and (min-width:350px) and (max-width: 389px){

    [data-aos^=fade][data-aos^=fade].aos-animate {
    width: 100% !important;
    }

    // Home Page

    #HomeBanner.overlay-two {
        height: 45%!important;
        width: 100% !important;
        margin: 0 !important;
        position: absolute;
        object-fit: cover;
        right: 0;
        bottom: 55% !important;
        z-index: -1;
    }

    h2.title.homepage-hero-title, .title.homepage-hero-title.h2 {
        font-size: 1.3rem !important;
    }
    .col.align-self-center {
        margin-top: 25% !important;
       // padding-left: 13%;
    }
    .desc.homepage-hero-desc {
        height: 35px !important;
    }

    .desc.homepage-hero-desc p{
        font-size: 0.7rem !important;
    }


    // .desc.homepage-hero-desc {
    // height: 50px !important;
    // }
    .overlay-two.home-banner-video {
        width: 100%;
        height: 56% !important;
    }

    #changeOnScroll {
        height: 3rem;
        width: 3rem;
        position: absolute;
        top: 1px;
        filter: drop-shadow(5px 5px 5px #222);
    }
    img#changeOnScroll {
        position: fixed !important;
        top: 10px !important;
        left: 50px !important;
    }

    .logoResize {
        height: 3rem !important;
        width: 3rem !important;
        position: static !important;
        // margin-top: 11px !important;
    }

    p.fact-desc {
    font-size: .9rem !important;
    }

    .card.overlay-two.efeb-img {
        height: 450px!important;
    }
    .section.home-demo-section.aos-animate {
        width: 100% !important;
    }
    h3.title.home-schedule-section-title {
        font-size: 1.5rem !important;
    }

    #leftB{
        left: 0rem !important
    }

    #logoTextResize{
        display: none;
    }

    #BizDesc{
    font-size:0.75rem;
    padding-top: 93px !important;
    }

        //Yant Page

        .swiper-container.swiper-container-coverflow.swiper-container-3d.swiper-container-initialized.swiper-container-horizontal.mySwiper {
            margin-left: auto !important;
        }
        .swiper-text {
            // max-width: 100% !important;
            width: 100% !important;
            // margin: 0rem !important;
            // font-size: 0.7rem;
        }
        .section.section-paddingTopBottom.mt-10.slider-section.aos-animate {
            max-width: 100% !important;
        }

        .offset-xl-1.col-xl-6.col-lg-6.col-12.meet-yant-cards {
            width: 100% !important;
        }

        #YantHeroText{
            left:0% !important;
        
        }
        .skill-video{
            padding: 0 !important;
            margin-top: 40px !important;
        }
        
        .desc{
            height: 258px !important;
        }
       
    
        #resizeSlide{
            width:  100% !important; 
        }

        .scroll-div{
            width: 100% !important;
            
        }
    
        #textWidth{
            max-width: 100% !important;
            font-size: 14px !important;

        }

        h5.meet-yant-h5-text {
            text-align: center;
        }
        p.meet-yant-p-text {
            text-align: center;
        }
      


    // Best8Eats Page

.custImage{
    display: none !important;
}
.restImage{
    display: none !important;
}

.intro-section {
 height: 300px !important;
}

.intro-section {
    height: auto !important;
}

.section.moreB8E-bg-img{
    height: 215px !important;
    
}
.moreB8E{
    margin-top: 0%;
}


.descMtext{
    height: 100% !important;
    margin-bottom: 15px!important;
    font-size: 25px !important;


}

.mobileWidth{
    width: 100% !important;
}

.h2.cs1-heading, .cs1-heading.h2{
    margin-top: 0px !important;
}

.newSpacebtw{

    margin-left: 2px !important;
    margin-top: 15px !important;
}
    // Contact Us Page

    #ContactIkabittext{
        font-size: 12px !important;
    }

    .section-title.text-center.aos-init.aos-animate{
        text-align: center !important;
        margin-top: 10px;
       
    }

    .intro-content-desc-p{
        font-size: 14px !important;
    }
 



    // About Us Page

    .vertical-divider{
        display: none !important;
    }

    #centerText{
        text-align: center !important;
    }
    #noJustify{
        font-size: 25px !important;
        text-align: center !important;
        text-justify: auto !important;
        word-spacing: -2px !important;

    }

    .margin-top-3{
        margin-top: 10px !important;


    }

    .contact-info{
        border-radius: 7% !important;
    }
    // IP Details Page
    // Schedule A Demo Page
}