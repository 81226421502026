/*----------------------------------------*/
/*  02. Template Spacing CSS
/*----------------------------------------*/

/* Section Spacing */
.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;

    // Responsive
    @media #{$tablet-device} {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.section-paddingTopBottom {
    padding-top: 55px;
    padding-bottom: 55px;

    // Responsive
    @media #{$tablet-device} {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @media #{$large-mobile} {
        padding-top: 50px;
        padding-bottom: 40px;
    }
}

.section-padTop {
    padding-top: 55px;

    // Responsive
    @media #{$tablet-device} {
        padding-top: 40px;
    }

    @media #{$large-mobile} {
        padding-top: 40px;
    }
}

.section-padBot {
    padding-bottom: 55px;

    // Responsive
    @media #{$tablet-device} {
        padding-bottom: 40px;
    }

    @media #{$large-mobile} {
        padding-bottom: 40px;
    }
}

.content-paddingTop {
    padding-top: 25px;

    // Responsive
    @media #{$tablet-device} {
        padding-top: 20px;
    }

    @media #{$large-mobile} {
        padding-top: 15px;
    }
}

.content-paddingBottom {
    padding-bottom: 25px;

    // Responsive
    @media #{$tablet-device} {
        padding-bottom: 20px;
    }

    @media #{$large-mobile} {
        padding-bottom: 15px;
    }
}

.content-paddingTopBottom {
    padding-top: 25px;
    padding-bottom: 25px;

    // Responsive
    @media #{$tablet-device} {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media #{$large-mobile} {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}


.section-padding-250{
    padding-top: 250px;
    padding-bottom: 250px;
    // Responsive
    @media #{$tablet-device}{
        padding-top: 180px;
        padding-bottom: 180px;
    }
    @media #{$large-mobile}{
        padding-top: 160px;
        padding-bottom: 160px;
    }
}
.section-padding-t90-b100 {
  padding-top: 90px;
  padding-bottom: 100px;

  // Responsive
  @media #{$tablet-device} {
      padding-top: 75px;
      padding-bottom: 80px;
  }

  @media #{$large-mobile} {
      padding-top: 55px;
      padding-bottom: 60px;
  }
}
.section-padding-t90{
  padding-top: 90px;
  // Responsive
  @media #{$tablet-device}{
      padding-top: 70px;
  }
  @media #{$large-mobile}{
      padding-top: 50px;
  }
}
.section-padding-t110-b120{
  padding-top: 110px;
  padding-bottom: 120px;
  // Responsive
  @media #{$desktop-device}{
      padding-top: 90px;
      padding-bottom: 100px;
  }
  @media #{$tablet-device}{
      padding-top: 70px;
      padding-bottom: 80px;
  }
  @media #{$large-mobile}{
      padding-top: 50px;
      padding-bottom: 60px;
  }
}
.section-padding-top {
    padding-top: 100px;

    // Responsive
    @media #{$tablet-device} {
        padding-top: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
    }
}
.section-padding-bottom {
    padding-bottom: 100px !important;

    // Responsive
    @media #{$tablet-device} {
        padding-bottom: 80px !important;
    }

    @media #{$large-mobile} {
        padding-bottom: 60px !important;
    }
}
.section-padding-bottom-150{
    padding-bottom: 150px;
    // Responsive
    @media #{$tablet-device}{
        padding-bottom: 180px;
    }
    @media #{$large-mobile}{
        padding-bottom: 160px;
    }
  }
.section-padding-bottom-160 {
    padding-bottom: 160px;

    // Responsive
    @media #{$desktop-device} {
        padding-bottom: 200px;
    }
    @media #{$tablet-device} {
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-bottom: 60px;
    }
}
.section-padding-bottom-170{
    padding-bottom: 170px;

    // Responsive
    @media #{$desktop-device} {
        padding-bottom: 200px;
    }
    @media #{$tablet-device} {
        padding-bottom: 280px;
    }

    @media #{$large-mobile} {
        padding-bottom: 260px;
    }
    @media #{$extra-small-mobile} {
        padding-bottom: 220px;
    }
}
.section-padding-bottom-180{
    padding-bottom: 180px;
    // Responsive
    @media #{$desktop-device} {
        padding-bottom: 200px;
    }
    @media #{$tablet-device} {
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-bottom: 60px;
    }
}
.technology-section-padding-bottom-180{
    padding-bottom: 180px;
    // Responsive
    @media #{$laptop-device} {
        padding-bottom: 140px;
    }
    @media #{$desktop-device} {
        padding-bottom: 200px;
    }
    @media #{$tablet-device} {
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-bottom: 60px;
    }
}
.section-padding-bottom-190{
    padding-bottom: 190px;
    // Responsive
    @media #{$desktop-device}{
        padding-bottom: 200px;
    }
    @media #{$tablet-device}{
        padding-bottom: 80px;
    }
    @media #{$large-mobile}{
        padding-bottom: 60px;
    }
  }
.section-padding-bottom-200{
    padding-bottom: 200px;
    // Responsive
    @media #{$laptop-device}{
        padding-bottom: 170px;
    }
    @media #{$desktop-device}{
        padding-bottom: 200px;
    }
    @media #{$tablet-device}{
        padding-bottom: 80px;
    }
    @media #{$large-mobile}{
        padding-bottom: 60px;
    }
  }
.about-section-padding-bottom-200{
    padding-bottom: 200px;
    // Responsive
    @media #{$laptop-device}{
        padding-bottom: 170px;
    }
    @media #{$desktop-device}{
        padding-bottom: 150px;
    }
    @media #{$tablet-device}{
        padding-bottom: 180px;
    }
    @media #{$large-mobile}{
        padding-bottom: 160px;
    }
}


#bgMat{
    position: relative;
}

#bgMat::before{
    content: "";
    background-image: url("https://assets-global.website-files.com/6222ca42ea87e1bd1aa1d10c/62231703c020d9735675ebe2_dot-seamless-pattern.svg") ;
    // opacity: 10%;
    background-size: 120px 120px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.15;
    
    // background-blend-mode:overlay;

   
  }

  .hoverCard{
    border: none;
    color:antiquewhite;

       &:hover{
           background-color: #1a315a;
           cursor: pointer;
           
       }
   }

   #clickArrow{
  
    &:hover{
       
        color: #fff !important ;
        transform: scale(10px);
        cursor: pointer;
    }
   }


   .slideCard{
    
    &:hover{
        width:600px;
        transition: width linear 2s;
        animation-timing-function: ease;
        // transform: translate(50px,100px) 2s;
        // transform: scaleX(2);
        // transition: scale(10);

    }
   }


 