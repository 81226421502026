/* // .StickyContainer{

//  display: flex;



// // background-color: black;
// .sticky{
//     width: 50%;

//     // height: 100vh;
//     // float: left;
//     // padding-top: 300px;
//     // padding-left: 100px;
//     // padding-right: 100px;
//     // background-color: white;
//     // position: relative;
//     // z-index: 1;
// }

// .sticky.fix{
//     position: fixed;
//     top: 2%;
//     margin-top: 3%;
//     display: inline-block;
// }



// .staticSide{
   
  



// }




// } */




.Sticky_main{
    display: flex;
    width: 100%;


}

.sticky{

    margin: 0 !important;
    
    
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
 

   
    
}
.text{
    padding: 100px;
}

/* .fixed{
    position: fixed;
    top: 6%;
    height: 100vh;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  

} */

.color1{
    color: #0054ff !important;
}

.color1:hover{
    color: #393f4d !important;
}


.color-blue{
    color: #2ea3f2;
}

.power-blue{
    color: #1400c6;
}

.power-blue:hover{
    color: #393f4d !important;
}





.color-blue:hover{
    color: #393f4d;
}

.static{
    width: 50%;
    margin: 0 !important;
    /* height: 250vh; */
    /* overflow: hidden; */
}


.page1, .page2{
    /* height: 120vh;   */
 
    display: flex;
    /* height: 130vh; */
    overflow: hidden;
    align-items:center ;
    justify-content: center;
    
    flex-direction: column;
}








